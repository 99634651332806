import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core";

export const containerStyles: BoxStyleProps = {
    border: "none",
    backgroundColor: "colorBackgroundBrandHighlight",
    display: "flex",
    flexDirection: "row",
    height: "sizeIcon90",
    fontSize: "fontSize50",
    alignItems: "center",
    justifyContent: "center",
    color: "colorTextWeakest",
    cursor: "pointer",
    transition: "background-color 0.2s",
    outline: "0px",
    _hover: {
        backgroundColor: "colorBackgroundBrandHighlightWeakest"
    },
    _focusVisible: {
        backgroundColor: "colorBackgroundBrandHighlightWeakest",
        boxShadow: "shadowFocus"
    }
};

export const textStyle: TextStyleProps = {
    color: "colorTextBrandInverse",
    marginRight: "space50"
};

export const containerStylesMobile: BoxStyleProps = {
    border: "none",
    backgroundColor: "colorBackgroundBrandHighlight",
    display: "flex",
    flexDirection: "row",
    height: "sizeIcon90",
    fontSize: "fontSize50",
    alignItems: "center",
    justifyContent: "center",
    color: "colorTextWeakest",
    cursor: "pointer",
    transition: "background-color 0.2s",
    outline: "0px",
    position: "fixed",
    bottom: "space50",
    right: "space60",
    _hover: {
        backgroundColor: "colorBackgroundBrandHighlightWeakest"
    },
    _focusVisible: {
        backgroundColor: "colorBackgroundBrandHighlightWeakest",
        boxShadow: "shadowFocus"
    }
};
