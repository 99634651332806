import { useDispatch, useSelector } from "react-redux";
import { CustomizationProvider, CustomizationProviderProps, PasteCustomCSS } from "@twilio-paste/core/customization";
import { CSSProperties, FC, useEffect } from "react";

import { RootContainer } from "./RootContainer";
import { EngagementPhase, AppState } from "../store/definitions";
import { sessionDataHandler } from "../sessionDataHandler";
import { initSession } from "../store/actions/initActions";
import { changeEngagementPhase } from "../store/actions/genericActions";
import { COMPANY } from "../constants";

const THEME_OVERRIDES_ACUBA = {
    backgroundColors: {
        // EntryPoint (Chatear Ahora boton)
        colorBackgroundBrandHighlight: "#FF0200",
        // Bienvenido a Acuba
        colorBackgroundBrand: "#FF0200",
        // on hover entry point
        colorBackgroundBrandHighlightWeakest: "rgb(194, 0, 11, 1)",
        // INICIAR CHAT button
        colorBackgroundPrimary: "#FF0200",
        // on hover INICIAR CHAT button
        colorBackgroundPrimaryStronger: "rgb(194, 0, 11, 1)"
    }
};

const THEME_OVERRIDES_CUBALLAMA = {
    backgroundColors: {
        colorBackgroundBrandHighlight: "rgb(30, 74, 204)",
        colorBackgroundBrandHighlightWeakest: "rgb(48, 69, 130)"
    }
};

const AnyCustomizationProvider: FC<CustomizationProviderProps & { style: CSSProperties }> = CustomizationProvider;

export function WebchatWidget() {
    const dispatch = useDispatch();
    const { isChatWebView } = useSelector((state: AppState) => state.config) || false;
    const customMobileElement: { [key: string]: PasteCustomCSS } = isChatWebView
        ? {
              LABEL: { fontSize: "fontSize40" },
              INPUT_ELEMENT: { fontSize: "fontSize40" },
              SELECT_ELEMENT: { fontSize: "fontSize40" },
              TEXT: { fontSize: "fontSize40" },
              TEXTAREA: { fontSize: "fontSize40" },
              TEXTAREA_ELEMENT: { fontSize: "fontSize40" },
              HELP_TEXT: { fontSize: "fontSize40" },
              BUTTON: { fontSize: "fontSize40" },
              MESSAGE_INPUT_ELEMENT: { fontSize: "fontSize40" }
          }
        : {};

    useEffect(() => {
        const data = sessionDataHandler.tryResumeExistingSession();
        const handleResize = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        };
        if (isChatWebView) {
            const vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            window.addEventListener("resize", handleResize);
        }
        if (data) {
            try {
                dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
            } catch (e) {
                // if initSession fails, go to changeEngagement phase - most likely there's something wrong with the store token or conversation sis
                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
            }
        } else {
            // if no token is stored, got engagement form
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }

        const globalStylesheet = document.querySelector('style[data-emotion="css-global"]');
        globalStylesheet?.parentNode?.removeChild(globalStylesheet);

        return () => {
            if (isChatWebView) {
                window.removeEventListener("resize", handleResize);
            }
        };
    }, [dispatch, isChatWebView]);

    return (
        <AnyCustomizationProvider
            baseTheme="default"
            theme={COMPANY === "acuba" ? THEME_OVERRIDES_ACUBA : THEME_OVERRIDES_CUBALLAMA}
            elements={{
                MESSAGE_INPUT: {
                    boxShadow: "none!important" as "none"
                },
                MESSAGE_INPUT_BOX: {
                    display: "inline-block",
                    boxShadow: "none"
                },
                ALERT: {
                    paddingTop: "space30",
                    paddingBottom: "space30"
                },
                BUTTON: {
                    "&[aria-disabled='true'][color='colorTextLink']": {
                        color: "colorTextLinkWeak"
                    }
                },
                ...customMobileElement
            }}
            style={{ minHeight: "100%", minWidth: "100%", fontSize: "100%" }}
        >
            <RootContainer />
        </AnyCustomizationProvider>
    );
}
