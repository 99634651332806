import { useEffect, useRef } from "react";
import { Text } from "@twilio-paste/core/text";
import { Button } from "@twilio-paste/core/button";
import { Box } from "@twilio-paste/core/box";
import FocusLock from "react-focus-lock";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { useSelector } from "react-redux";

import { containerStyles, containerStylesMobile } from "./styles/ModalTrigger.styles";
import { AppState } from "../store/definitions";
import { COMPANY } from "../constants";

function useEscapeKey(callback: () => void) {
    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (event.code === "Escape") {
                callback();
                return undefined;
            }
            return undefined;
        }

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [callback]);
}

function useOnClickOutside(ref: React.RefObject<HTMLElement>, callback: () => void) {
    useEffect(() => {
        function handleMouseDown(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
                return undefined;
            }
            return undefined;
        }
        window.addEventListener("mousedown", handleMouseDown);
        return () => {
            window.removeEventListener("mousedown", handleMouseDown);
        };
    }, [ref, callback]);
}

function ModalTrigger({
    handleDismiss,
    handleAction,
    isLoading = false
}: {
    handleDismiss: () => void;
    handleAction: () => void;
    isLoading: boolean;
}) {
    const { isChatWebView } = useSelector((state: AppState) => state.config) || {};
    const modalRef = useRef<HTMLTextAreaElement>(null);
    useOnClickOutside(modalRef, handleDismiss);
    useEscapeKey(handleDismiss);
    return (
        <FocusLock returnFocus={true}>
            <Box
                as="div"
                {...(isChatWebView ? containerStylesMobile : containerStyles)}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 8000 }}
            >
                <Box
                    backgroundColor="colorBackgroundBody"
                    marginTop="space200"
                    marginX="space60"
                    padding="space40"
                    borderRadius="borderRadius30"
                    borderStyle="solid"
                    boxShadow="shadowBorder"
                    borderColor="colorBorderWeaker"
                    ref={modalRef}
                >
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="end">
                        <Box
                            variant="secondary_icon"
                            as="button"
                            backgroundColor="transparent"
                            size="icon_small"
                            border="none"
                            cursor="pointer"
                            padding="space0"
                            onClick={handleDismiss}
                        >
                            <CloseIcon decorative={false} title="Cerrar dialogo" size="sizeIcon20" color="colorText" />
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        flexWrap="nowrap"
                        textAlign="center"
                    >
                        <Text as="p" paddingX="space80" paddingY="space60" whiteSpace="nowrap">
                            ¿Estás seguro que quieres cerrar el chat?
                        </Text>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        <Button
                            variant={COMPANY === "cuballama" ? "primary" : "destructive"}
                            onClick={handleAction}
                            loading={isLoading}
                        >
                            Cerrar Chat
                        </Button>
                    </Box>
                </Box>
            </Box>
        </FocusLock>
    );
}

export default ModalTrigger;
