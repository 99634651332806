import { Flex } from "@twilio-paste/core/flex";
import { Spinner } from "@twilio-paste/core/spinner";

import { COMPANY } from "../constants";

export const LoadingPhase = () => {
    return (
        <Flex hAlignContent="center" vAlignContent="center" height="100vh">
            <Spinner
                title="Authorizing"
                decorative={false}
                size="sizeIcon100"
                color={COMPANY === "acuba" ? "colorTextError" : "colorTextLink"}
            />
        </Flex>
    );
};
