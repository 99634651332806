import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Box } from "@twilio-paste/core/box";
import { TextArea } from "@twilio-paste/core/textarea";
import { FormEvent, useState, useEffect, useRef } from "react";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { HelpText } from "@twilio-paste/core/help-text";
import { Select, Option } from "@twilio-paste/core/select";

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { introStyles, fieldStyles, formStyles } from "./styles/PreEngagementFormPhase.styles";
import { COMPANY } from "../constants";

interface DropdownOption {
    value: string;
}

interface FormFieldProps {
    id: string;
    label: string;
    type?: "text" | "email" | "tel";
    value: string | undefined;
    errorMessage: string | null;
    placeholder?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    isTextArea?: boolean;
    isDropdown?: boolean;
    wasSubmitted: boolean;
    dropdownOptions?: Array<DropdownOption>;
}

const FormField: React.FC<FormFieldProps> = ({
    id,
    label,
    type = "text",
    value,
    errorMessage,
    placeholder,
    onChange,
    isTextArea = false,
    isDropdown = false,
    wasSubmitted = false,
    dropdownOptions = []
}) => {
    const [touched, setTouched] = useState(false);
    const timeoutRef = useRef<number | null>(null);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const handleBlur = () => {
        setTouched(true);
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }
    };
    const displayErrorMessage = Boolean((wasSubmitted || touched) && errorMessage);
    const handleFocus = () => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = window.setTimeout(() => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            inputRef?.current?.scrollIntoView();
        }, 300);
    };

    let InputElement;
    if (isTextArea) {
        InputElement = (
            <TextArea
                id={id}
                name={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                ref={inputRef as React.RefObject<HTMLTextAreaElement>}
            />
        );
    } else if (isDropdown) {
        InputElement = (
            <Select
                id={id}
                name={id}
                onChange={onChange}
                onBlur={handleBlur}
                value={value ? value : "default"}
                // ref={inputRef as React.RefObject<HTMLSelectElement>}
            >
                <Option value="default" disabled hidden>
                    Selecciona una opción
                </Option>
                {dropdownOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                        {option.value}
                    </Option>
                ))}
            </Select>
        );
    } else {
        InputElement = (
            <Input
                id={id}
                type={type}
                name={id}
                placeholder={placeholder}
                value={value}
                data-test={`pre-engagement-chat-form-${id}-input`}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                // ref={inputRef as React.RefObject<HTMLInputElement>}
            />
        );
    }

    return (
        <Box {...fieldStyles}>
            <Label htmlFor={id}>{label}</Label>
            {InputElement}
            {displayErrorMessage ? <HelpText variant="error">{errorMessage}</HelpText> : null}
        </Box>
    );
};

export const PreEngagementFormPhase = () => {
    const { name, email, query, telephone, motive } =
        useSelector((state: AppState) => state.session.preEngagementData) || {};
    const { lob, sessionData } = useSelector((state: AppState) => state.config) || {};
    const dispatch = useDispatch();
    const [wasSubmitted, setWasSubmitted] = useState(false);
    const [formFields, setFormFields] = useState([]);

    useEffect(() => {
        const endpoint = process.env.REACT_APP_GLOBAL_SERVICE_URL || "";
        async function getFormFields() {
            const body = {
                company: process.env.REACT_APP_COMPANY && process.env.REACT_APP_COMPANY.toLowerCase(),
                lob
            };
            try {
                const response = await fetch(`${endpoint}/webchat/pull-form-fields`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(body)
                });
                if (response.ok) {
                    const result = await response.json();
                    if (Array.isArray(result?.fields) && result.fields.length > 0) {
                        const newFormFields = result.fields.map((field: string) => {
                            return {
                                value: field
                            };
                        });
                        setFormFields(newFormFields);
                    }
                } else {
                    throw new Error("An error ocurred while fetching form fields");
                }
            } catch (e) {}
        }
        getFormFields();
    }, [lob]);

    const getFieldError = ({
        value,
        isTelephone = false,
        isEmail = false
    }: {
        value: string | undefined;
        isTelephone?: boolean;
        isEmail?: boolean;
    }) => {
        if (!value) return "Campo requerido";

        if (isEmail) {
            const isValidEmail = /^\S+@\S+\.\S+$/.test(value);
            if (!isValidEmail) {
                return COMPANY === "acuba"
                    ? "Correo electrónico inválido"
                    : "Por favor, indica un correo electrónico válido";
            }
        }

        if (isTelephone) {
            const isNumber = /^[0-9]+$/.test(value);
            if (!isNumber) {
                return COMPANY === "acuba" ? "Teléfono invalido" : "Por favor, indica un teléfono válido";
            }
        }

        return null;
    };

    const nameErrorMessage = getFieldError({ value: name });
    const emailErrorMessage = getFieldError({ value: email, isEmail: true });
    const telephoneErrorMessage = getFieldError({ value: telephone, isTelephone: true });
    const queryErrorMessage = getFieldError({ value: query });
    const motiveErrorMessage = getFieldError({ value: motive });
    const isFormValid =
        name &&
        email &&
        telephone &&
        query &&
        motive &&
        !nameErrorMessage &&
        !emailErrorMessage &&
        !telephoneErrorMessage &&
        !queryErrorMessage &&
        !motiveErrorMessage;

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setWasSubmitted(true);
        if (!isFormValid) {
            return;
        }
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        let clientIpAddress = "";
        try {
            const response = await fetch("https://api.ipify.org/?format=json");
            if (response.ok) {
                const data = await response.json();
                clientIpAddress = data.ip;
            } else {
                throw new Error("Error during fetch. Unable to retrieve data");
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log((err as Error).message);
        }
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName: name,
                    friendlyEmail: email,
                    friendlyPhone: telephone,
                    friendlySelect: motive,
                    locationOrigin: clientIpAddress,
                    query,
                    source: lob,
                    userId: sessionData?.userId || "Anonymous",
                    ...sessionData
                }
            });
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <Text {...introStyles} as="p">
                    {COMPANY === "acuba" &&
                        "Bienvenido al chat de Atención al Cliente de aCuba! Antes de iniciar el chat, por favor, complete este breve formulario."}
                    {COMPANY === "cuballama" &&
                        "Bienvenido al chat de Atención al Cliente de Cuballama. Antes de comenzar, por favor, completa este formulario."}
                </Text>
                <FormField
                    type="text"
                    id="name"
                    label="Nombre *"
                    placeholder={COMPANY === "acuba" ? "Nombre" : "Indica tu nombre"}
                    errorMessage={nameErrorMessage}
                    value={name}
                    onChange={(e) => dispatch(updatePreEngagementData({ name: e.target.value }))}
                    wasSubmitted={wasSubmitted}
                />
                <FormField
                    type="email"
                    id="email"
                    label="Correo Electrónico *"
                    placeholder={COMPANY === "acuba" ? "Correo Electrónico" : "Indica tu email"}
                    errorMessage={emailErrorMessage}
                    value={email}
                    onChange={(e) => dispatch(updatePreEngagementData({ email: e.target.value }))}
                    wasSubmitted={wasSubmitted}
                />
                <FormField
                    type="tel"
                    id="tel"
                    label={
                        COMPANY === "acuba"
                            ? "Teléfono comenzando con el código de país (1 para USA y Canadá) *"
                            : "Número de celular *"
                    }
                    placeholder={
                        COMPANY === "acuba"
                            ? "Teléfono comenzando con el código de país (1 para USA y Canadá) *"
                            : "Con código país (1 para USA y Canadá)"
                    }
                    errorMessage={telephoneErrorMessage}
                    value={telephone}
                    onChange={(e) => dispatch(updatePreEngagementData({ telephone: e.target.value }))}
                    wasSubmitted={wasSubmitted}
                />
                <FormField
                    type="text"
                    isDropdown={true}
                    dropdownOptions={formFields}
                    id="motive"
                    label={
                        COMPANY === "acuba"
                            ? "Por favor seleccione el tema mas apropiado para ayudarle *"
                            : "Tema de tu consulta *"
                    }
                    errorMessage={motiveErrorMessage}
                    value={motive}
                    onChange={(e) => dispatch(updatePreEngagementData({ motive: e.target.value }))}
                    wasSubmitted={wasSubmitted}
                />
                <FormField
                    type="text"
                    isTextArea={true}
                    id="query"
                    label={COMPANY === "acuba" ? "Describa brevemente el tema seleccionado *" : "Describe tu duda *"}
                    placeholder={
                        COMPANY === "acuba"
                            ? "Describa brevemente el tema seleccionado"
                            : "Explica, tan breve como puedas, tu consulta"
                    }
                    errorMessage={queryErrorMessage}
                    value={query}
                    onChange={(e) => dispatch(updatePreEngagementData({ query: e.target.value }))}
                    wasSubmitted={wasSubmitted}
                />

                <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button" style={{}}>
                    {COMPANY === "acuba" && "INICIAR CHAT"}
                    {COMPANY === "cuballama" && "COMENZAR"}
                </Button>
            </Box>
        </>
    );
};
