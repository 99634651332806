import { Box } from "@twilio-paste/core/box";
import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { Text } from "@twilio-paste/core/text";
import { useDispatch, useSelector } from "react-redux";

import { changeExpandedStatus } from "../store/actions/genericActions";
import { AppState } from "../store/definitions";
import { containerStyles, textStyle, containerStylesMobile } from "./styles/EntryPoint.styles";
import { COMPANY } from "../constants";

export const EntryPoint = () => {
    const dispatch = useDispatch();
    const expanded = useSelector((state: AppState) => state.session.expanded);
    const { isChatWebView } = useSelector((state: AppState) => state.config) || {};

    return (
        <Box
            as="button"
            data-test="entry-point-button"
            onClick={() => dispatch(changeExpandedStatus({ expanded: !expanded }))}
            style={expanded ? { borderRadius: "50%", width: "2.5rem" } : { borderRadius: "24px", padding: "1rem" }}
            {...(isChatWebView ? containerStylesMobile : containerStyles)}
        >
            {expanded ? (
                <ChevronDownIcon
                    decorative={false}
                    title="Minimizar chat"
                    size="sizeIcon80"
                    style={{ pointerEvents: "none" }}
                />
            ) : (
                <>
                    <Text as="p" {...textStyle} style={{ pointerEvents: "none" }}>
                        {COMPANY === "acuba" ? "Chatear Ahora" : "Chat Cuballama"}
                    </Text>
                    <ChatIcon
                        decorative={false}
                        title="Abrir chat"
                        size="sizeIcon50"
                        style={{ pointerEvents: "none" }}
                    />
                </>
            )}
        </Box>
    );
};
