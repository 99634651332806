import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";

import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import { AppState, EngagementPhase } from "../store/definitions";
import { PreEngagementFormPhase } from "./PreEngagementFormPhase";
import { LoadingPhase } from "./LoadingPhase";
import { EntryPoint } from "./EntryPoint";
import {
    innerContainerStyles,
    outerContainerStyles,
    outerContainerStylesMobile,
    innerContainerStylesMobile
} from "./styles/RootContainer.styles";

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.PreEngagementForm:
        default:
            return <PreEngagementFormPhase />;
    }
};

export function RootContainer() {
    const { currentPhase, expanded } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded
    }));
    const { isChatWebView } = useSelector((state: AppState) => state.config) || {};

    return (
        <Box>
            <Box {...(isChatWebView ? outerContainerStylesMobile : outerContainerStyles)} style={{ zIndex: 1000 }}>
                {expanded && (
                    <Box
                        data-test="root-container"
                        {...(isChatWebView ? innerContainerStylesMobile : innerContainerStyles)}
                    >
                        {getPhaseComponent(currentPhase)}
                    </Box>
                )}
                {isChatWebView && expanded ? null : <EntryPoint />}
            </Box>
        </Box>
    );
}
