import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

export const containerStyles: BoxStyleProps = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "colorBackgroundBrand",
    padding: "space30",
    paddingTop: "space20",
    paddingBottom: "space20",
    borderTopLeftRadius: "borderRadius20",
    borderTopRightRadius: "borderRadius20"
};

export const containerStylesMobile: BoxStyleProps = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "colorBackgroundBrand",
    padding: "space30",
    paddingTop: "space20",
    paddingBottom: "space20",
    borderTopLeftRadius: "borderRadius20",
    borderTopRightRadius: "borderRadius20",
    position: "sticky",
    top: "0px"
};

export const titleStyles: TextStyleProps = {
    color: "colorTextWeakest",
    fontSize: "fontSize40"
};

export const buttonStyles: BoxStyleProps = {
    border: "none",
    backgroundColor: "colorBackgroundBrand",
    display: "flex",
    flexDirection: "row",
    height: "sizeIcon60",
    alignItems: "center",
    justifyContent: "center",
    color: "colorTextWeakest",
    cursor: "pointer",
    transition: "background-color 0.2s",
    padding: "space10",
    borderRadius: "borderRadius20",
    outline: "0px",
    _hover: {
        backgroundColor: "colorBackgroundBrandHighlightWeakest"
    },
    _focusVisible: {
        backgroundColor: "colorBackgroundBrandHighlightWeakest",
        boxShadow: "shadowFocus"
    }
};
