import { BoxStyleProps } from "@twilio-paste/core/box";

export const containerStyles: BoxStyleProps = {
    position: "fixed",
    bottom: "space50",
    right: "space60",
    width: "320px",
    height: "590px",
    marginBottom: "space160",
    display: "flex",
    flexDirection: "column",
    alignContent: "center"
};

export const containerStylesMobile: BoxStyleProps = {
    position: "fixed",
    bottom: "space0",
    right: "space0",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignContent: "center"
};
