import { useState, useCallback } from "react";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { CheckboxLineIcon } from "@twilio-paste/icons/esm/CheckboxLineIcon";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../store/definitions";
import { changeExpandedStatus } from "../store/actions/genericActions";
import { COMPANY } from "../constants";
import { containerStyles, titleStyles, buttonStyles, containerStylesMobile } from "./styles/Header.styles";
import { ReactComponent as AcubaLogoWhite } from "../utils/assets/icons_acuba_white.svg";
import { ReactComponent as CuballamaLogoWhite } from "../utils/assets/cuballama-logo-symbol-white.svg";
import { ACTION_UPDATE_CONVERSATION_STATE } from "../store/actions/actionTypes";
import ModalTrigger from "./ModalTrigger";
import { sessionDataHandler, contactBackend } from "../sessionDataHandler";
import { CloseWebchat, Survey } from "../definitions";

export const Header = ({ customTitle }: { customTitle?: string }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { expanded, conversation, conversationState, isChatWebView } = useSelector((state: AppState) => ({
        expanded: state.session.expanded,
        conversation: state.chat.conversation,
        conversationState: state.chat.conversationState,
        isChatWebView: state.config.isChatWebView
    }));

    const Logo = COMPANY === "acuba" ? AcubaLogoWhite : CuballamaLogoWhite;

    const handleAction = useCallback(async () => {
        setIsLoading(true);
        let preparedMessage = conversation?.prepareMessage();
        preparedMessage = preparedMessage?.setBody("El cliente abandonó el chat");
        preparedMessage = preparedMessage?.setAttributes({ notification: true });
        await preparedMessage?.build().send();

        const data = sessionDataHandler.tryResumeExistingSession();
        if (data) {
            try {
                await contactBackend<Survey>("/survey", {
                    token: data.token,
                    conversationSid: data.conversationSid
                });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }

            setIsLoading(false);
            dispatch({
                type: ACTION_UPDATE_CONVERSATION_STATE,
                payload: { conversationState: "closed" }
            });

            try {
                await contactBackend<CloseWebchat>("/closeWebchat", {
                    token: data.token,
                    conversationSid: data.conversationSid
                });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }
    }, [conversation, dispatch]);

    return (
        <Box as="header" {...(isChatWebView ? containerStylesMobile : containerStyles)}>
            <Logo title="logo" style={{ width: "auto", height: "auto", maxWidth: "120px", maxHeight: "36px" }} />
            <Text as="h2" {...titleStyles}>
                {COMPANY ? `Bienvenido a ${process.env.REACT_APP_COMPANY}` : customTitle}
            </Text>
            <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
                {!isChatWebView && (
                    <Box
                        as="button"
                        size="icon_small"
                        onClick={() => dispatch(changeExpandedStatus({ expanded: !expanded }))}
                        {...buttonStyles}
                        paddingTop="space40"
                        marginRight="space10"
                        backgroundColor="colorBackgroundBrand"
                    >
                        <CheckboxLineIcon
                            decorative={false}
                            title="Minimizar Chat"
                            size="sizeIcon20"
                            style={{ cursor: "pointer" }}
                            color="colorTextWeakest"
                        />
                    </Box>
                )}
                {conversationState === "active" && !isChatWebView && (
                    <>
                        <Box
                            variant="secondary_icon"
                            as="button"
                            backgroundColor="transparent"
                            size="icon_small"
                            onClick={() => {
                                setIsOpen(true);
                            }}
                            {...buttonStyles}
                        >
                            <CloseIcon
                                decorative={false}
                                title="Cerrar Chat"
                                size="sizeIcon20"
                                style={{ cursor: "pointer" }}
                                color="colorTextWeakest"
                            />
                        </Box>
                        {isOpen && (
                            <ModalTrigger
                                handleDismiss={() => setIsOpen(false)}
                                handleAction={handleAction}
                                isLoading={isLoading}
                            />
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};
